<template>
  <div class="container">
    <h3>Ops... você não tem permissão para acessar este conteúdo.</h3>
    <p><small>Caso necessite desta permissão, entre em contato com um administrador do sistema.</small></p>
  </div>
  
</template>

<script>
export default {
  
}
</script>
